import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, Redirect, Route, Router, Switch } from 'react-router-dom'
import { RouteDefinition, RouteWithSubRoutes } from './RouteWithSubRoutes'
import AppContext, { createContext, useAppContext } from './config/context'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import './config/fontAwesome'
import { RevealerAdmin } from './Involvement/Revealer'
import { observer } from 'mobx-react-lite'
import { AppliNavBar } from './Navbar'
import { InvolvementProfilesAdmin } from './InvolvementProfiles/InvolvementProfilesAdmin'
import { Toaster } from '@blueprintjs/core'
import { defaultToasterProps, ToasterContext } from './common/Toaster'
import { DictionaryAdmin } from './Dictionary/DictionaryAdmin'
import { ImpotSocieteAdmin } from './ImpotSociete/ImpotSocieteAdmin'
import { ApolloProvider } from '@apollo/client'
import { MementoAdmin } from './memento/MementoAdmin'
import { Analytics } from './analytics/analytics'
import { Training } from "./training/Training"

dayjs.locale('fr')

export const Container = styled.div`
  padding: 10px;
`

const routes: RouteDefinition[] = [
  {
    path: '/home',
    Component: () => (
      <div>
        <h1>Admin</h1>
        <ul>
          <li>
            <Link to={'/involvement'}>Révélateur d'engagement</Link>
          </li>
          <li>
            <Link to={'/involvement_profiles'}>Profils d'engagement</Link>
          </li>
          <li>
            <Link to={'/dictionary'}>Dictionnaire</Link>
          </li>
          <li>
            <Link to={'/impot-societe'}>Impôt sur les sociétés</Link>
          </li>
          <li>
            <Link to={'/memento'}>Mémento</Link>
          </li>
          <li>
            <Link to={'/analytics'}>Analytics</Link>
          </li>
          <li>
            <Link to={'/training'}>Formation</Link>
          </li>
        </ul>
      </div>
    ),
    secured: true,
  },
  {
    path: '/involvement',
    Component: RevealerAdmin,
    secured: true,
  },
  {
    path: '/involvement_profiles',
    Component: InvolvementProfilesAdmin,
    secured: true,
  },
  {
    path: '/dictionary',
    Component: DictionaryAdmin,
    secured: true,
  },
  {
    path: '/impot-societe',
    Component: ImpotSocieteAdmin,
    secured: true,
  },
  {
    path: '/memento',
    Component: MementoAdmin,
    secured: true,
  },
  {
    path: '/analytics',
    Component: Analytics,
    secured: true,
  },
  {
    path: '/training',
    Component: Training,
    secured: true,
  },
  {
    path: '/unauthorized',
    Component: () => {
      const { authStore } = useAppContext()
      return (
        <div>
          {authStore.authStatus !== 'UNAUTHORIZED' && <Redirect to={'/'} />}
          PAS LE DROIT <button onClick={() => authStore.logout()}>Logout</button>
        </div>
      )
    },
  },
]
const serviceAndStore = createContext()

export const AdminApp = () => (
  <AppContext.Provider value={serviceAndStore}>
    <ApolloProvider client={serviceAndStore.apolloClient}>
      <Router history={serviceAndStore.routingStore.history}>
        <Content />
      </Router>
    </ApolloProvider>
  </AppContext.Provider>
)
const Content = observer(() => {
  const [toaster, setToaster] = useState<Toaster | null>()
  return (
    <>
      <AppliNavBar />
      <Toaster {...defaultToasterProps} ref={(r) => setToaster(r)} />
      <ToasterContext.Provider value={toaster!}>
        <Container>
          <Switch>
            {routes.map((route) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
            <Route render={() => <Redirect to={'/home'} />} />
          </Switch>
        </Container>
      </ToasterContext.Provider>
    </>
  )
})
