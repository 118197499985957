import { MementoTxtHelpDocument, useUpdateMementoTxtHelpMutation } from '../generated/graphql'
import {
  MementoTxtExplicatif,
  MementoTxtExplicatifInput,
  MementoTxtHelpQuery,
  MementoTxtHelpQueryVariables,
} from '@fa-metier/types'
import produce from 'immer'

export const useMementoTxtHelpUpdate = (champ: string) => {
  const [mementoTxtMutation] = useUpdateMementoTxtHelpMutation({
    update: (cache, mutationResult) => {
      if (!mutationResult.data) {
        return
      }
      const readQuery = cache.readQuery<MementoTxtHelpQuery, MementoTxtHelpQueryVariables>({
        query: MementoTxtHelpDocument,
      })!
      cache.writeQuery<MementoTxtHelpQuery, MementoTxtHelpQueryVariables>({
        query: MementoTxtHelpDocument,
        data: produce(readQuery, (draft) => {
          draft.mementoTxtHelp = draft.mementoTxtHelp.map((txtHelp) => {
            if (txtHelp.champ === champ) {
              txtHelp.aideComprehension =
                mutationResult.data!!.mementoTxtHelp.update.aideComprehension
              txtHelp.title = mutationResult.data!!.mementoTxtHelp.update.title
            }
            return txtHelp
          })
        }),
      })
    },
  })
  return (updatedMementoTxtHelp: MementoTxtExplicatifInput) => {
    return new Promise<MementoTxtExplicatif>((resolve, reject) => {
      mementoTxtMutation({
        variables: { champ, input: updatedMementoTxtHelp },
      })
        .then((response) => {
          const result = response.data && response.data.mementoTxtHelp.update
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
