import React, {FC, useEffect, useState} from 'react'
import {DictionaryDocument, useDictionaryQuery, useUpdateLabelMutation,} from '../generated/graphql'
import {Label, LabelKeys} from '@fa-metier/types'
import {Breadcrumbs, Button, InputGroup} from '@blueprintjs/core'
import {useToaster} from '../common/Toaster'
import {BreadcrumbRenderer, HelpBlock} from '@fa-metier/components'

export const DictionaryAdmin: FC = () => {
  const profiles = useDictionary()

  if (!profiles.length) {
    return <>Chargement</>
  }

  return (
    <div>
      <Breadcrumbs
        items={[
          { href: '/home', text: 'Administration' },
          { text: 'Dictionnaire', current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <h1>Dictionnaire</h1>

      <HelpBlock>
        <>
          <div>Paramétrage des différents libellés qui aparaissent sur YODA</div>
        </>
      </HelpBlock>

      {profiles.map((it: Label) => (
        <div key={it.cle}>
          <span>{descriptions[it.cle]} :</span> <LabelEditor {...it} />
        </div>
      ))}
    </div>
  )
}

const LabelEditor: FC<Pick<Label, 'value' | 'cle'>> = (label) => {
  const [updatedLabel, updateLabel] = useState<string>(label.value)
  const toaster = useToaster()
  const [updateFn] = useUpdateLabelMutation({
    update: (cache) => {
      cache.evictQueries([{ query: DictionaryDocument }])
    },
    onCompleted: () => {
      toaster.show({
        message: 'Enregistrement terminé',
        intent: 'success',
        icon: 'saved',
      })
    },
    onError: (error) => {
      console.error(error)
      toaster.show({
        message: "Erreur : l'enregistrement a échoué",
        intent: 'danger',
        icon: 'error',
      })
    },
  })

  useEffect(() => {
    updateLabel(label.value)
  }, [label, updateLabel])

  const launchUpdate = async () => {
    await updateFn({
      variables: {
        request: {
          cle: label.cle,
          value: updatedLabel,
        },
      },
    })
  }

  return (
    <InputGroup
      rightElement={
        <Button
          disabled={label.value === updatedLabel}
          icon={'edit'}
          onClick={() => launchUpdate()}
        />
      }
      value={updatedLabel}
      onChange={(v: any) => updateLabel(v.target.value)}
    />
  )
}

export const useDictionary = () => {
  const { data, loading } = useDictionaryQuery()
  if (!loading && data) {
    return data.dictionary.labels
  }
  return []
}

const descriptions: Record<LabelKeys, string> = {
  BONUS_CRITERION: "Critère bonus du révélateur d'engagement",
  BONUS_CRITERION_DESCRIPTION: "Aide avancée du critère bonus du révélateur d'engagement",
  IC_EXAMPLE_0: "Révélateur d'engagement : description de la valeur 0",
  IC_EXAMPLE_1: "Révélateur d'engagement : description de la valeur 1",
  IC_EXAMPLE_2: "Révélateur d'engagement : description de la valeur 2",
  IC_EXAMPLE_3: "Révélateur d'engagement : description de la valeur 3",
}
