import * as Types from '@fa-metier/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export const RubriqueMementoFieldsFragmentDoc = gql`
    fragment RubriqueMementoFields on RubriqueMemento {
  id
  ordre
  titre
  description
  aideComprehensionEntrepreneur
  active
}
    `;
export const DictionaryDocument = gql`
    query Dictionary {
  dictionary {
    labels {
      cle
      value
    }
  }
}
    `;

/**
 * __useDictionaryQuery__
 *
 * To run a query within a React component, call `useDictionaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDictionaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDictionaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDictionaryQuery(baseOptions?: Apollo.QueryHookOptions<Types.DictionaryQuery, Types.DictionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DictionaryQuery, Types.DictionaryQueryVariables>(DictionaryDocument, options);
      }
export function useDictionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DictionaryQuery, Types.DictionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DictionaryQuery, Types.DictionaryQueryVariables>(DictionaryDocument, options);
        }
export type DictionaryQueryHookResult = ReturnType<typeof useDictionaryQuery>;
export type DictionaryLazyQueryHookResult = ReturnType<typeof useDictionaryLazyQuery>;
export type DictionaryQueryResult = Apollo.QueryResult<Types.DictionaryQuery, Types.DictionaryQueryVariables>;
export const UpdateLabelDocument = gql`
    mutation UpdateLabel($request: LabelInput!) {
  dictionary {
    modifyLabel(request: $request) {
      cle
      value
    }
  }
}
    `;
export type UpdateLabelMutationFn = Apollo.MutationFunction<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateLabelMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>(UpdateLabelDocument, options);
      }
export type UpdateLabelMutationHookResult = ReturnType<typeof useUpdateLabelMutation>;
export type UpdateLabelMutationResult = Apollo.MutationResult<Types.UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>;
export const ImpotSocieteParamsDocument = gql`
    query ImpotSocieteParams {
  impotSocieteParam {
    impotSocieteParams {
      year
      taux1
      taux2
      seuil
    }
  }
}
    `;

/**
 * __useImpotSocieteParamsQuery__
 *
 * To run a query within a React component, call `useImpotSocieteParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpotSocieteParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpotSocieteParamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useImpotSocieteParamsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ImpotSocieteParamsQuery, Types.ImpotSocieteParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ImpotSocieteParamsQuery, Types.ImpotSocieteParamsQueryVariables>(ImpotSocieteParamsDocument, options);
      }
export function useImpotSocieteParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ImpotSocieteParamsQuery, Types.ImpotSocieteParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ImpotSocieteParamsQuery, Types.ImpotSocieteParamsQueryVariables>(ImpotSocieteParamsDocument, options);
        }
export type ImpotSocieteParamsQueryHookResult = ReturnType<typeof useImpotSocieteParamsQuery>;
export type ImpotSocieteParamsLazyQueryHookResult = ReturnType<typeof useImpotSocieteParamsLazyQuery>;
export type ImpotSocieteParamsQueryResult = Apollo.QueryResult<Types.ImpotSocieteParamsQuery, Types.ImpotSocieteParamsQueryVariables>;
export const CreateImpotSocieteParamDocument = gql`
    mutation createImpotSocieteParam($input: ImpotSocieteParamCreateRequestInput!) {
  impotSocieteParam {
    create(input: $input) {
      year
      taux1
      taux2
      seuil
    }
  }
}
    `;
export type CreateImpotSocieteParamMutationFn = Apollo.MutationFunction<Types.CreateImpotSocieteParamMutation, Types.CreateImpotSocieteParamMutationVariables>;

/**
 * __useCreateImpotSocieteParamMutation__
 *
 * To run a mutation, you first call `useCreateImpotSocieteParamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImpotSocieteParamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImpotSocieteParamMutation, { data, loading, error }] = useCreateImpotSocieteParamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImpotSocieteParamMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateImpotSocieteParamMutation, Types.CreateImpotSocieteParamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateImpotSocieteParamMutation, Types.CreateImpotSocieteParamMutationVariables>(CreateImpotSocieteParamDocument, options);
      }
export type CreateImpotSocieteParamMutationHookResult = ReturnType<typeof useCreateImpotSocieteParamMutation>;
export type CreateImpotSocieteParamMutationResult = Apollo.MutationResult<Types.CreateImpotSocieteParamMutation>;
export type CreateImpotSocieteParamMutationOptions = Apollo.BaseMutationOptions<Types.CreateImpotSocieteParamMutation, Types.CreateImpotSocieteParamMutationVariables>;
export const UpdateImpotSocieteParamDocument = gql`
    mutation UpdateImpotSocieteParam($year: Int!, $input: ImpotSocieteParamSaveRequestInput!) {
  impotSocieteParam {
    patch(year: $year, input: $input) {
      year
      taux1
      taux2
      seuil
    }
  }
}
    `;
export type UpdateImpotSocieteParamMutationFn = Apollo.MutationFunction<Types.UpdateImpotSocieteParamMutation, Types.UpdateImpotSocieteParamMutationVariables>;

/**
 * __useUpdateImpotSocieteParamMutation__
 *
 * To run a mutation, you first call `useUpdateImpotSocieteParamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImpotSocieteParamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImpotSocieteParamMutation, { data, loading, error }] = useUpdateImpotSocieteParamMutation({
 *   variables: {
 *      year: // value for 'year'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImpotSocieteParamMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateImpotSocieteParamMutation, Types.UpdateImpotSocieteParamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateImpotSocieteParamMutation, Types.UpdateImpotSocieteParamMutationVariables>(UpdateImpotSocieteParamDocument, options);
      }
export type UpdateImpotSocieteParamMutationHookResult = ReturnType<typeof useUpdateImpotSocieteParamMutation>;
export type UpdateImpotSocieteParamMutationResult = Apollo.MutationResult<Types.UpdateImpotSocieteParamMutation>;
export type UpdateImpotSocieteParamMutationOptions = Apollo.BaseMutationOptions<Types.UpdateImpotSocieteParamMutation, Types.UpdateImpotSocieteParamMutationVariables>;
export const QuestionsAdminDocument = gql`
    query QuestionsAdmin {
  involvementCheck {
    categories {
      category
      questions {
        id
        description
        text
        category
        questionVersion
      }
    }
  }
}
    `;

/**
 * __useQuestionsAdminQuery__
 *
 * To run a query within a React component, call `useQuestionsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionsAdminQuery(baseOptions?: Apollo.QueryHookOptions<Types.QuestionsAdminQuery, Types.QuestionsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionsAdminQuery, Types.QuestionsAdminQueryVariables>(QuestionsAdminDocument, options);
      }
export function useQuestionsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionsAdminQuery, Types.QuestionsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionsAdminQuery, Types.QuestionsAdminQueryVariables>(QuestionsAdminDocument, options);
        }
export type QuestionsAdminQueryHookResult = ReturnType<typeof useQuestionsAdminQuery>;
export type QuestionsAdminLazyQueryHookResult = ReturnType<typeof useQuestionsAdminLazyQuery>;
export type QuestionsAdminQueryResult = Apollo.QueryResult<Types.QuestionsAdminQuery, Types.QuestionsAdminQueryVariables>;
export const UpdateQuestionsDocument = gql`
    mutation UpdateQuestions($request: [QuestionSaveRequestInputWithId!]!) {
  involvementCheck {
    updateQuestions(request: $request) {
      id
    }
  }
}
    `;
export type UpdateQuestionsMutationFn = Apollo.MutationFunction<Types.UpdateQuestionsMutation, Types.UpdateQuestionsMutationVariables>;

/**
 * __useUpdateQuestionsMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionsMutation, { data, loading, error }] = useUpdateQuestionsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionsMutation, Types.UpdateQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionsMutation, Types.UpdateQuestionsMutationVariables>(UpdateQuestionsDocument, options);
      }
export type UpdateQuestionsMutationHookResult = ReturnType<typeof useUpdateQuestionsMutation>;
export type UpdateQuestionsMutationResult = Apollo.MutationResult<Types.UpdateQuestionsMutation>;
export type UpdateQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionsMutation, Types.UpdateQuestionsMutationVariables>;
export const InvolvementProfilesConfigDocument = gql`
    query InvolvementProfilesConfig {
  involvementCheck {
    profiles {
      label
      profile
    }
  }
}
    `;

/**
 * __useInvolvementProfilesConfigQuery__
 *
 * To run a query within a React component, call `useInvolvementProfilesConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvolvementProfilesConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvolvementProfilesConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvolvementProfilesConfigQuery(baseOptions?: Apollo.QueryHookOptions<Types.InvolvementProfilesConfigQuery, Types.InvolvementProfilesConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvolvementProfilesConfigQuery, Types.InvolvementProfilesConfigQueryVariables>(InvolvementProfilesConfigDocument, options);
      }
export function useInvolvementProfilesConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvolvementProfilesConfigQuery, Types.InvolvementProfilesConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvolvementProfilesConfigQuery, Types.InvolvementProfilesConfigQueryVariables>(InvolvementProfilesConfigDocument, options);
        }
export type InvolvementProfilesConfigQueryHookResult = ReturnType<typeof useInvolvementProfilesConfigQuery>;
export type InvolvementProfilesConfigLazyQueryHookResult = ReturnType<typeof useInvolvementProfilesConfigLazyQuery>;
export type InvolvementProfilesConfigQueryResult = Apollo.QueryResult<Types.InvolvementProfilesConfigQuery, Types.InvolvementProfilesConfigQueryVariables>;
export const UpdateProfileConfigDocument = gql`
    mutation UpdateProfileConfig($profile: InvolvementProfile!, $label: String!) {
  involvementCheck {
    updateProfile(profile: $profile, label: $label) {
      label
      profile
    }
  }
}
    `;
export type UpdateProfileConfigMutationFn = Apollo.MutationFunction<Types.UpdateProfileConfigMutation, Types.UpdateProfileConfigMutationVariables>;

/**
 * __useUpdateProfileConfigMutation__
 *
 * To run a mutation, you first call `useUpdateProfileConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileConfigMutation, { data, loading, error }] = useUpdateProfileConfigMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useUpdateProfileConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileConfigMutation, Types.UpdateProfileConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileConfigMutation, Types.UpdateProfileConfigMutationVariables>(UpdateProfileConfigDocument, options);
      }
export type UpdateProfileConfigMutationHookResult = ReturnType<typeof useUpdateProfileConfigMutation>;
export type UpdateProfileConfigMutationResult = Apollo.MutationResult<Types.UpdateProfileConfigMutation>;
export type UpdateProfileConfigMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileConfigMutation, Types.UpdateProfileConfigMutationVariables>;
export const NoteByAtDocument = gql`
    query NoteByAt($dateDebut: String, $dateFin: String) {
  noteByAt(dateDebut: $dateDebut, dateFin: $dateFin) {
    at
    noteCount
  }
}
    `;

/**
 * __useNoteByAtQuery__
 *
 * To run a query within a React component, call `useNoteByAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteByAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteByAtQuery({
 *   variables: {
 *      dateDebut: // value for 'dateDebut'
 *      dateFin: // value for 'dateFin'
 *   },
 * });
 */
export function useNoteByAtQuery(baseOptions?: Apollo.QueryHookOptions<Types.NoteByAtQuery, Types.NoteByAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NoteByAtQuery, Types.NoteByAtQueryVariables>(NoteByAtDocument, options);
      }
export function useNoteByAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NoteByAtQuery, Types.NoteByAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NoteByAtQuery, Types.NoteByAtQueryVariables>(NoteByAtDocument, options);
        }
export type NoteByAtQueryHookResult = ReturnType<typeof useNoteByAtQuery>;
export type NoteByAtLazyQueryHookResult = ReturnType<typeof useNoteByAtLazyQuery>;
export type NoteByAtQueryResult = Apollo.QueryResult<Types.NoteByAtQuery, Types.NoteByAtQueryVariables>;
export const MementoAnalyticsDocument = gql`
    query MementoAnalytics($startDate: String, $endDate: String) {
  mementoAnalytics(startDate: $startDate, endDate: $endDate) {
    associationMementoClickAnalytics {
      associationName
      projectsCount
      projectsCountWithAtLeastOneDownload
      projectsCountWithAtLeastOnePublication
      projectsRateWithoutAnyDownload
      projectsRateWithExactlyOneDownload
      projectsRateWithMultipleDownloads
    }
  }
}
    `;

/**
 * __useMementoAnalyticsQuery__
 *
 * To run a query within a React component, call `useMementoAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMementoAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMementoAnalyticsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useMementoAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MementoAnalyticsQuery, Types.MementoAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MementoAnalyticsQuery, Types.MementoAnalyticsQueryVariables>(MementoAnalyticsDocument, options);
      }
export function useMementoAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MementoAnalyticsQuery, Types.MementoAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MementoAnalyticsQuery, Types.MementoAnalyticsQueryVariables>(MementoAnalyticsDocument, options);
        }
export type MementoAnalyticsQueryHookResult = ReturnType<typeof useMementoAnalyticsQuery>;
export type MementoAnalyticsLazyQueryHookResult = ReturnType<typeof useMementoAnalyticsLazyQuery>;
export type MementoAnalyticsQueryResult = Apollo.QueryResult<Types.MementoAnalyticsQuery, Types.MementoAnalyticsQueryVariables>;
export const MementoTxtHelpDocument = gql`
    query mementoTxtHelp {
  mementoTxtHelp {
    champ
    title
    aideComprehension
  }
}
    `;

/**
 * __useMementoTxtHelpQuery__
 *
 * To run a query within a React component, call `useMementoTxtHelpQuery` and pass it any options that fit your needs.
 * When your component renders, `useMementoTxtHelpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMementoTxtHelpQuery({
 *   variables: {
 *   },
 * });
 */
export function useMementoTxtHelpQuery(baseOptions?: Apollo.QueryHookOptions<Types.MementoTxtHelpQuery, Types.MementoTxtHelpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MementoTxtHelpQuery, Types.MementoTxtHelpQueryVariables>(MementoTxtHelpDocument, options);
      }
export function useMementoTxtHelpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MementoTxtHelpQuery, Types.MementoTxtHelpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MementoTxtHelpQuery, Types.MementoTxtHelpQueryVariables>(MementoTxtHelpDocument, options);
        }
export type MementoTxtHelpQueryHookResult = ReturnType<typeof useMementoTxtHelpQuery>;
export type MementoTxtHelpLazyQueryHookResult = ReturnType<typeof useMementoTxtHelpLazyQuery>;
export type MementoTxtHelpQueryResult = Apollo.QueryResult<Types.MementoTxtHelpQuery, Types.MementoTxtHelpQueryVariables>;
export const UpdateMementoTxtHelpDocument = gql`
    mutation updateMementoTxtHelp($champ: String!, $input: MementoTxtExplicatifInput!) {
  mementoTxtHelp {
    update(champ: $champ, input: $input) {
      champ
      title
      aideComprehension
    }
  }
}
    `;
export type UpdateMementoTxtHelpMutationFn = Apollo.MutationFunction<Types.UpdateMementoTxtHelpMutation, Types.UpdateMementoTxtHelpMutationVariables>;

/**
 * __useUpdateMementoTxtHelpMutation__
 *
 * To run a mutation, you first call `useUpdateMementoTxtHelpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMementoTxtHelpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMementoTxtHelpMutation, { data, loading, error }] = useUpdateMementoTxtHelpMutation({
 *   variables: {
 *      champ: // value for 'champ'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMementoTxtHelpMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMementoTxtHelpMutation, Types.UpdateMementoTxtHelpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMementoTxtHelpMutation, Types.UpdateMementoTxtHelpMutationVariables>(UpdateMementoTxtHelpDocument, options);
      }
export type UpdateMementoTxtHelpMutationHookResult = ReturnType<typeof useUpdateMementoTxtHelpMutation>;
export type UpdateMementoTxtHelpMutationResult = Apollo.MutationResult<Types.UpdateMementoTxtHelpMutation>;
export type UpdateMementoTxtHelpMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMementoTxtHelpMutation, Types.UpdateMementoTxtHelpMutationVariables>;
export const RubriquesMementoDocument = gql`
    query rubriquesMemento {
  rubriquesMemento {
    ...RubriqueMementoFields
  }
}
    ${RubriqueMementoFieldsFragmentDoc}`;

/**
 * __useRubriquesMementoQuery__
 *
 * To run a query within a React component, call `useRubriquesMementoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRubriquesMementoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRubriquesMementoQuery({
 *   variables: {
 *   },
 * });
 */
export function useRubriquesMementoQuery(baseOptions?: Apollo.QueryHookOptions<Types.RubriquesMementoQuery, Types.RubriquesMementoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RubriquesMementoQuery, Types.RubriquesMementoQueryVariables>(RubriquesMementoDocument, options);
      }
export function useRubriquesMementoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RubriquesMementoQuery, Types.RubriquesMementoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RubriquesMementoQuery, Types.RubriquesMementoQueryVariables>(RubriquesMementoDocument, options);
        }
export type RubriquesMementoQueryHookResult = ReturnType<typeof useRubriquesMementoQuery>;
export type RubriquesMementoLazyQueryHookResult = ReturnType<typeof useRubriquesMementoLazyQuery>;
export type RubriquesMementoQueryResult = Apollo.QueryResult<Types.RubriquesMementoQuery, Types.RubriquesMementoQueryVariables>;
export const UpdateRubriqueMementoDocument = gql`
    mutation updateRubriqueMemento($id: ID!, $input: RubriqueMementoInput!) {
  rubriqueMemento {
    update(id: $id, input: $input) {
      ...RubriqueMementoFields
    }
  }
}
    ${RubriqueMementoFieldsFragmentDoc}`;
export type UpdateRubriqueMementoMutationFn = Apollo.MutationFunction<Types.UpdateRubriqueMementoMutation, Types.UpdateRubriqueMementoMutationVariables>;

/**
 * __useUpdateRubriqueMementoMutation__
 *
 * To run a mutation, you first call `useUpdateRubriqueMementoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRubriqueMementoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRubriqueMementoMutation, { data, loading, error }] = useUpdateRubriqueMementoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRubriqueMementoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRubriqueMementoMutation, Types.UpdateRubriqueMementoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRubriqueMementoMutation, Types.UpdateRubriqueMementoMutationVariables>(UpdateRubriqueMementoDocument, options);
      }
export type UpdateRubriqueMementoMutationHookResult = ReturnType<typeof useUpdateRubriqueMementoMutation>;
export type UpdateRubriqueMementoMutationResult = Apollo.MutationResult<Types.UpdateRubriqueMementoMutation>;
export type UpdateRubriqueMementoMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRubriqueMementoMutation, Types.UpdateRubriqueMementoMutationVariables>;
export const AddRubriqueMementoDocument = gql`
    mutation addRubriqueMemento {
  rubriqueMemento {
    add {
      ...RubriqueMementoFields
    }
  }
}
    ${RubriqueMementoFieldsFragmentDoc}`;
export type AddRubriqueMementoMutationFn = Apollo.MutationFunction<Types.AddRubriqueMementoMutation, Types.AddRubriqueMementoMutationVariables>;

/**
 * __useAddRubriqueMementoMutation__
 *
 * To run a mutation, you first call `useAddRubriqueMementoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRubriqueMementoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRubriqueMementoMutation, { data, loading, error }] = useAddRubriqueMementoMutation({
 *   variables: {
 *   },
 * });
 */
export function useAddRubriqueMementoMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddRubriqueMementoMutation, Types.AddRubriqueMementoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddRubriqueMementoMutation, Types.AddRubriqueMementoMutationVariables>(AddRubriqueMementoDocument, options);
      }
export type AddRubriqueMementoMutationHookResult = ReturnType<typeof useAddRubriqueMementoMutation>;
export type AddRubriqueMementoMutationResult = Apollo.MutationResult<Types.AddRubriqueMementoMutation>;
export type AddRubriqueMementoMutationOptions = Apollo.BaseMutationOptions<Types.AddRubriqueMementoMutation, Types.AddRubriqueMementoMutationVariables>;
export const DeleteRubriqueMementoDocument = gql`
    mutation deleteRubriqueMemento($id: ID!) {
  rubriqueMemento {
    delete(id: $id)
  }
}
    `;
export type DeleteRubriqueMementoMutationFn = Apollo.MutationFunction<Types.DeleteRubriqueMementoMutation, Types.DeleteRubriqueMementoMutationVariables>;

/**
 * __useDeleteRubriqueMementoMutation__
 *
 * To run a mutation, you first call `useDeleteRubriqueMementoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRubriqueMementoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRubriqueMementoMutation, { data, loading, error }] = useDeleteRubriqueMementoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRubriqueMementoMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRubriqueMementoMutation, Types.DeleteRubriqueMementoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRubriqueMementoMutation, Types.DeleteRubriqueMementoMutationVariables>(DeleteRubriqueMementoDocument, options);
      }
export type DeleteRubriqueMementoMutationHookResult = ReturnType<typeof useDeleteRubriqueMementoMutation>;
export type DeleteRubriqueMementoMutationResult = Apollo.MutationResult<Types.DeleteRubriqueMementoMutation>;
export type DeleteRubriqueMementoMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRubriqueMementoMutation, Types.DeleteRubriqueMementoMutationVariables>;
export const TrainingProjectsByAssociationDocument = gql`
    query trainingProjectsByAssociation {
  trainingProjectsByAssociation {
    data {
      associationId
      projects
    }
  }
}
    `;

/**
 * __useTrainingProjectsByAssociationQuery__
 *
 * To run a query within a React component, call `useTrainingProjectsByAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProjectsByAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProjectsByAssociationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingProjectsByAssociationQuery(baseOptions?: Apollo.QueryHookOptions<Types.TrainingProjectsByAssociationQuery, Types.TrainingProjectsByAssociationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TrainingProjectsByAssociationQuery, Types.TrainingProjectsByAssociationQueryVariables>(TrainingProjectsByAssociationDocument, options);
      }
export function useTrainingProjectsByAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TrainingProjectsByAssociationQuery, Types.TrainingProjectsByAssociationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TrainingProjectsByAssociationQuery, Types.TrainingProjectsByAssociationQueryVariables>(TrainingProjectsByAssociationDocument, options);
        }
export type TrainingProjectsByAssociationQueryHookResult = ReturnType<typeof useTrainingProjectsByAssociationQuery>;
export type TrainingProjectsByAssociationLazyQueryHookResult = ReturnType<typeof useTrainingProjectsByAssociationLazyQuery>;
export type TrainingProjectsByAssociationQueryResult = Apollo.QueryResult<Types.TrainingProjectsByAssociationQuery, Types.TrainingProjectsByAssociationQueryVariables>;
export const CopyProjectNoteDocument = gql`
    mutation copyProjectNote($projectId: Int!, $request: CopyNoteRequest!) {
  training {
    copyProjectNote(projectId: $projectId, request: $request) {
      status
      updatedProjectsIds
    }
  }
}
    `;
export type CopyProjectNoteMutationFn = Apollo.MutationFunction<Types.CopyProjectNoteMutation, Types.CopyProjectNoteMutationVariables>;

/**
 * __useCopyProjectNoteMutation__
 *
 * To run a mutation, you first call `useCopyProjectNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyProjectNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyProjectNoteMutation, { data, loading, error }] = useCopyProjectNoteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCopyProjectNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CopyProjectNoteMutation, Types.CopyProjectNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CopyProjectNoteMutation, Types.CopyProjectNoteMutationVariables>(CopyProjectNoteDocument, options);
      }
export type CopyProjectNoteMutationHookResult = ReturnType<typeof useCopyProjectNoteMutation>;
export type CopyProjectNoteMutationResult = Apollo.MutationResult<Types.CopyProjectNoteMutation>;
export type CopyProjectNoteMutationOptions = Apollo.BaseMutationOptions<Types.CopyProjectNoteMutation, Types.CopyProjectNoteMutationVariables>;