import {
  ImpotSocieteParamsDocument,
  useCreateImpotSocieteParamMutation,
  useUpdateImpotSocieteParamMutation,
} from '../generated/graphql'
// @ts-ignore
import {
  ImpotSocieteParam,
  ImpotSocieteParamCreateRequestInput,
  ImpotSocieteParamSaveRequestInput,
} from '@fa-metier/types'

export const useCreateImpotSocieteParam = () => {
  const [createImpotSocieteParam] = useCreateImpotSocieteParamMutation({
    update: (cache => {
      cache.evictQueries([
        {
          query: ImpotSocieteParamsDocument,
        },
      ])
    })
  })
  return (input: ImpotSocieteParamCreateRequestInput) => {
    return new Promise<ImpotSocieteParam>((resolve, reject) => {
      createImpotSocieteParam({
        variables: { input },
      })
        .then(response => {
          const result = response.data && response.data.impotSocieteParam.create
          result ? resolve(result) : reject()
        })
        .catch(error => reject(error))
    })
  }
}

export const useUpdateImpotSocieteParam = () => {
  const [updateImpotSocieteParam] = useUpdateImpotSocieteParamMutation({
    update: (cache =>{
      cache.evictQueries(
        [
          {
            query: ImpotSocieteParamsDocument,
          },
        ]
      )
    })
  })
  return (year: number, input: ImpotSocieteParamSaveRequestInput) => {
    return new Promise<ImpotSocieteParam>((resolve, reject) => {
      updateImpotSocieteParam({
        variables: { year, input },
      })
        .then(response => {
          const result = response.data && response.data.impotSocieteParam.patch
          result ? resolve(result) : reject()
        })
        .catch(error => reject(error))
    })
  }
}
