import React, { FC, useState } from 'react'
import { Breadcrumbs, Button, FormGroup, Intent, NumericInput, Spinner } from '@blueprintjs/core'
import { BreadcrumbRenderer } from '@fa-metier/components'
import '@blueprintjs/core/lib/css/blueprint.css'
import {
  useCopyProjectNoteMutation,
  useTrainingProjectsByAssociationQuery,
} from '../generated/graphql'
import { useToaster } from '../common/Toaster'

export const Training: FC = () => {
  const { loading, data } = useTrainingProjectsByAssociationQuery()

  const toaster = useToaster()

  const [sourceProject, setSourceProject] = useState<number>()
  const [destinationProjectsIds, setDestinationProjectsIds] = useState<number[]>([])

  const [copyProjectNoteMutation, { loading: copyInProgress }] = useCopyProjectNoteMutation()

  const copyProjectNode = async () => {
    try {
      const fetchResult = await copyProjectNoteMutation({
        variables: { projectId: sourceProject ?? 0, request: { destinationProjectsIds } },
      })
      const response = fetchResult.data?.training.copyProjectNote

      if (!!response) {
        toaster.show({
          message: `Copie de la note du projet ${sourceProject} vers les notes des projets [${response.updatedProjectsIds.join(
            ','
          )}] effectuée avec succès`,
          intent: Intent.SUCCESS,
        })
      }

      return response
    } catch (e) {
      toaster.show({
        message: `Une erreur est survenue lors de la copie de la note.`,
        intent: Intent.DANGER,
      })
    }
  }

  if (loading) {
    return <Spinner size={30} />
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { href: '/home', text: 'Administration' },
          { text: 'Formation', current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <h2>Copie de données note à note</h2>
      <p>
        A partir d'un ID projet Nouba renseigné, copier les données de la note associée au projet
        vers les notes associées aux projets cibles. Les types de note doivent correspondre, les notes des projets
        cibles dont le type ne correspond pas seront ignorées.
      </p>
      <p>
        Les projets cibles sont fixes et classés par AT. Plusieurs projets peuvent être sélectionnés
        en même temps avec CTRL + Clic ou Maj + Clic.
      </p>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <FormGroup label="Projet source" labelFor="source-project" inline>
            <NumericInput
              id="source-project"
              placeholder="ID du projet"
              stepSize={undefined}
              buttonPosition={'none'}
              onValueChange={(value) => setSourceProject(Math.trunc(Number(value)))}
            />
          </FormGroup>
          <Button
            style={{ width: '10em', marginLeft: '1em' }}
            className="bp3-intent-primary"
            onClick={copyProjectNode}
            loading={copyInProgress}
          >
            Copier
          </Button>
        </div>
        <select
          multiple
          size={30}
          onChange={(e) =>
            setDestinationProjectsIds(
              Array.from(e.target.options)
                .filter((o) => o.selected)
                .map((o) => Number(o.value.split(' - ')[0]))
            )
          }
        >
          {data!!.trainingProjectsByAssociation.data.map(({ associationId, projects }) => (
            <optgroup key={associationId} label={associationId}>
              {projects.map((project) => (
                <option key={project} value={project}>
                  {project}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </div>
    </>
  )
}
