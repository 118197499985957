import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useQuestionsAdminQuery, useUpdateQuestionsMutation } from '../generated/graphql'
import {
  InvolvementCheckCategory,
  Question,
  QuestionsAdminQuery,
  QuestionSaveRequestInputWithId,
} from '@fa-metier/types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QuestionAdmin } from './QuestionAdmin'
import classNames from 'classnames'
import { Breadcrumbs, Button, Collapse } from '@blueprintjs/core'
import { BreadcrumbRenderer } from '@fa-metier/components'
import produce from 'immer'
import { useToaster } from '../common/Toaster'
import { CATEGORY_CONFIG, getIcon, replace } from '@fa-metier/commons'

const Section = styled.section`
  padding: 0 1em;

  h3 {
    display: flex;
    align-items: center;
    padding: 0.25em;
    background-color: var(--color);
    margin-bottom: 0;
    justify-content: space-between;
    cursor: pointer;
  }

  .collapse-panel > div {
    > div:nth-child(2n) {
      position: relative;
      background-color: var(--bg-color);
    }

    > div {
      position: relative;
      background-color: white;
      transition: background-color 100ms linear;
      //&:hover {
      //  background-color: var(--bg-color-focused);
      //}
    }
  }

  .panel-button {
    transition: all ease 500ms;
    transform: rotate(0);
    margin-right: 8px;

    &.toggled {
      transform: rotate(180deg);
    }
  }

  .bp3-form-content {
    flex: 1;
  }

  .bp3-label {
    font-size: 18px;
    font-weight: 500;
  }
`

type InvolvementCheck = QuestionsAdminQuery['involvementCheck']

export const RevealerAdmin: FC = observer(() => {
  const toaster = useToaster()
  const { loading, data } = useQuestionsAdminQuery()
  const [involvementCheck, setInvolvementCheck] = useState<InvolvementCheck>()
  const [hasChange, setHasChange] = useState(false)

  const updateAction = (updatedCategory: InvolvementCheckCategory) => {
    setInvolvementCheck((involvementCheck) => ({
      ...involvementCheck!,
      categories: replace(
        involvementCheck!.categories,
        updatedCategory,
        (c) => c.category === updatedCategory.category
      ),
    }))
    setHasChange(true)
  }

  useEffect(() => {
    !loading && setInvolvementCheck(produce(data!.involvementCheck, () => {}))
  }, [loading, data, setInvolvementCheck])

  const [updateQuestions] = useUpdateQuestionsMutation({
    onCompleted: (_) => {
      setHasChange(false)
      toaster.show({
        message: 'Enregistrement terminé',
        intent: 'success',
        icon: 'saved',
      })
    },
    onError: (error) => {
      console.error(error)
      toaster.show({
        message: "Erreur : l'enregistrement a échoué",
        intent: 'danger',
        icon: 'error',
      })
    },
  })

  const sendUpdateQuestions = () =>
    updateQuestions({
      variables: {
        request: involvementCheck!.categories
          .flatMap((c) => c.questions)
          .map<QuestionSaveRequestInputWithId>((q) => ({
            id: q.id,
            request: {
              category: q.category,
              description: q.description,
              text: q.text,
            },
          })),
      },
    })

  return (
    <>
      {loading && <div>Chargement</div>}
      {!loading && involvementCheck && (
        <>
          <Breadcrumbs
            items={[
              { href: '/home', text: 'Administration' },
              { text: "Révélateur d'engagement", current: true },
            ]}
            breadcrumbRenderer={BreadcrumbRenderer}
          />
          <div style={{ display: 'flex', alignItems: 'baseline', padding: '1em 1em 0.5em' }}>
            <h1>Révélateur d'engagement</h1>
            {/*<div style={{ textDecoration: 'underline' }}>{`Sauvegardé le ${dayjs(new Date()).format(*/}
            {/*  'ddd DD MMMM - HH:mm'*/}
            {/*)}`}</div>*/}
            <div style={{ flexGrow: 1 }} />
            <div>
              <Button
                icon={'share'}
                text={'Enregistrer'}
                intent={hasChange ? 'success' : 'none'}
                disabled={!hasChange}
                onClick={sendUpdateQuestions}
              />
            </div>
          </div>
          <div>
            {involvementCheck.categories.map((c) => (
              <CategoryAdmin
                key={c.category}
                category={c}
                hasChange={hasChange}
                updateCategory={(updatedCategory) => updateAction(updatedCategory)}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
})

interface CategoryAdminProps {
  category: InvolvementCheckCategory
  hasChange: boolean
  updateCategory: (category: InvolvementCheckCategory) => void
}

const CategoryAdmin: FC<CategoryAdminProps> = ({ category, hasChange, updateCategory }) => {
  const catConf = CATEGORY_CONFIG[category.category]

  const [isOpen, setOpen] = useState<boolean>(false)

  const cx = classNames('panel-button', isOpen ? 'toggled' : '')

  const updateAction = (updatedQuestion: Question) => {
    updateCategory({
      ...category,
      questions: replace(category.questions, updatedQuestion, (q) => q.id === updatedQuestion.id),
    })
  }

  return (
    <Section>
      <h3 onClick={() => setOpen(!isOpen)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getIcon(category.category, 'iconTitre', 32)}
          {catConf.label}
        </div>
        <div className={cx}>
          <FontAwesomeIcon icon={'chevron-up'} />
        </div>
      </h3>
      <Collapse isOpen={isOpen} className={'collapse-panel'}>
        {category.questions.map((question) => (
          <QuestionAdmin
            key={question.id}
            question={question}
            hasChange={hasChange}
            updateQuestion={(q) => updateAction(q)}
          />
        ))}
      </Collapse>
    </Section>
  )
}
