import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBell,
  faLeaf,
  faHandshake,
  faSitemap,
  faMapMarkerAlt,
  faUsers,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'

library.add(faBell, faLeaf, faHandshake, faSitemap, faMapMarkerAlt, faUsers, faChevronUp)
