import {
  RubriquesMementoDocument,
  useAddRubriqueMementoMutation,
  useDeleteRubriqueMementoMutation,
  useRubriquesMementoQuery,
  useUpdateRubriqueMementoMutation,
} from '../generated/graphql'
import {
  RubriqueMemento,
  RubriqueMementoInput,
  RubriquesMementoQuery,
  RubriquesMementoQueryVariables,
} from '@fa-metier/types'
import produce from 'immer'

export const useGetRubriquesMemento = () => {
  const { loading, data } = useRubriquesMementoQuery()
  return {
    loading,
    data: data ? data.rubriquesMemento : undefined,
  }
}

export const useAddRubriqueMemento = () => {
  const [addRubrique] = useAddRubriqueMementoMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<RubriquesMementoQuery, RubriquesMementoQueryVariables>({
        query: RubriquesMementoDocument,
        variables: {},
      })!
      cache.writeQuery<RubriquesMementoQuery, RubriquesMementoQueryVariables>({
        query: RubriquesMementoDocument,
        data: produce(readQuery, (draft) => {
          if (draft.rubriquesMemento && mutationResult.data) {
            draft.rubriquesMemento.push(mutationResult.data.rubriqueMemento.add)
          }
        }),
      })
    },
  })
  return () => {
    return new Promise<RubriqueMemento>((resolve, reject) => {
      addRubrique()
        .then((response) => {
          const result = response.data && response.data.rubriqueMemento.add
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useDeleteRubriqueMemento = (id: string) => {
  const [deleteMutation] = useDeleteRubriqueMementoMutation({
    refetchQueries: [
      {
        query: RubriquesMementoDocument,
        variables: {},
      },
    ],
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<RubriquesMementoQuery, RubriquesMementoQueryVariables>({
        query: RubriquesMementoDocument,
        variables: {},
      })!
      cache.writeQuery<RubriquesMementoQuery, RubriquesMementoQueryVariables>({
        query: RubriquesMementoDocument,
        variables: {},
        data: produce(readQuery, (draft) => {
          if (mutationResult.data?.rubriqueMemento?.delete) {
            draft.rubriquesMemento = draft.rubriquesMemento.filter((rubrique) => rubrique.id !== id)
          }
        }),
      })
    },
  })
  return () => {
    return new Promise<boolean>((resolve, reject) => {
      deleteMutation({
        variables: { id },
      })
        .then((response) => {
          const result = response.data?.rubriqueMemento.delete
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useUpdateRubriqueMemento = (id: string, orderChange: boolean) => {
  const [updateMutation] = useUpdateRubriqueMementoMutation({
    refetchQueries: orderChange
      ? [
          {
            query: RubriquesMementoDocument,
            variables: {},
          },
        ]
      : [],
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<RubriquesMementoQuery, RubriquesMementoQueryVariables>({
        query: RubriquesMementoDocument,
        variables: {},
      })!
      cache.writeQuery<RubriquesMementoQuery, RubriquesMementoQueryVariables>({
        query: RubriquesMementoDocument,
        data: produce(readQuery, (draft) => {
          if (mutationResult.data && draft && draft.rubriquesMemento) {
            draft.rubriquesMemento = draft.rubriquesMemento.map((rubrique) => {
              if (rubrique.id === id) {
                rubrique.titre = mutationResult.data!!.rubriqueMemento.update.titre
                rubrique.description = mutationResult.data!!.rubriqueMemento.update.description
                rubrique.active = mutationResult.data!!.rubriqueMemento.update.active
              }
              return rubrique
            })
          }
        }),
      })
    },
  })
  return (rubriqueMementoInput: RubriqueMementoInput) => {
    return new Promise<RubriqueMemento>((resolve, reject) => {
      updateMutation({
        variables: { id, input: rubriqueMementoInput },
      })
        .then((response) => {
          const result = response.data && response.data.rubriqueMemento.update
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
