import React, { FC, useState } from 'react'
import { Breadcrumbs, Tab, Tabs } from '@blueprintjs/core'
import { BreadcrumbRenderer } from '@fa-metier/components'
import { MementoTxtHelp } from './MementoTxtHelpEditor'
import { RubriqueMementoForm } from './RubriqueMementoForm'

export const MementoAdmin: FC = () => {
  const [selectedTab, setSelectedTab] = useState('memento-rubrique')
  return (
    <div>
      <Breadcrumbs
        items={[
          { href: '/home', text: 'Administration' },
          { text: 'Memento', current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <h1>Memento</h1>

      <div style={{ padding: '10px' }}>
        <Tabs selectedTabId={selectedTab} onChange={(id: string) => setSelectedTab(id)}>
          <Tab id={'memento-rubrique'} title={'Rubriques'} />
          <Tab id={'memento-help'} title={"Textes d'aide"} />
          <Tab id={'memento-profils'} title={"Profils d'entrepreneur"} />
        </Tabs>
      </div>
      {selectedTab === 'memento-help' && <MementoTxtHelp profils={false} />}
      {selectedTab === 'memento-profils' && <MementoTxtHelp profils={true} />}
      {selectedTab === 'memento-rubrique' && <RubriqueMementoForm />}
    </div>
  )
}
