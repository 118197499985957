import React, { FC, useEffect, useState } from 'react'
import {
  InvolvementProfilesConfigDocument,
  useInvolvementProfilesConfigQuery,
  useUpdateProfileConfigMutation,
} from '../generated/graphql'
import {
  InvolvementProfile,
  InvolvementProfileConfig,
  InvolvementProfilesConfigQuery,
} from '@fa-metier/types'
import { Breadcrumbs, Button, InputGroup } from '@blueprintjs/core'
import produce from 'immer'
import { useToaster } from '../common/Toaster'
import { BreadcrumbRenderer, HelpBlock } from '@fa-metier/components'

export const InvolvementProfilesAdmin: FC = () => {
  const profiles = useInvolvementProfilesConfig()

  if (!profiles.length) {
    return <>Chargement</>
  }

  return (
    <div>
      <Breadcrumbs
        items={[
          { href: '/home', text: 'Administration' },
          { text: "Profils d'engagement", current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <h1>Profils d'engagement</h1>
      <HelpBlock>
        <>
          <div>Caractérisation des différents profils d'engagement. </div>
          <div>
            Ils apparaitront (seuls ou combinés entre eux) au niveau du récapitulatif du révélateur
            d'engagement en fonction des notes globales par catégorie
          </div>
        </>
      </HelpBlock>
      {profiles.map((it) => (
        <div key={it.profile}>
          {descriptions[it.profile]} : <ProfileLabelEditor {...it} />
        </div>
      ))}
    </div>
  )
}

const ProfileLabelEditor: FC<Pick<InvolvementProfileConfig, 'label' | 'profile'>> = ({
  profile,
  label,
}) => {
  const [updatedLabel, updateLabel] = useState<string>(label)
  const toaster = useToaster()
  const [updateFn] = useUpdateProfileConfigMutation({
    update: (proxy, { data }) => {
      const query = proxy.readQuery<InvolvementProfilesConfigQuery>({
        query: InvolvementProfilesConfigDocument,
      })!
      proxy.writeQuery<InvolvementProfilesConfigQuery>({
        query: InvolvementProfilesConfigDocument,
        data: produce(query, (draft) => {
          draft.involvementCheck.profiles
            .filter((c) => c.profile === profile)
            .forEach((c) => (c.label = data!.involvementCheck.updateProfile.label))
        }),
      })
    },
    onCompleted: () => {
      toaster.show({
        message: 'Enregistrement terminé',
        intent: 'success',
        icon: 'saved',
      })
    },
    onError: (error) => {
      console.error(error)
      toaster.show({
        message: "Erreur : l'enregistrement a échoué",
        intent: 'danger',
        icon: 'error',
      })
    },
  })

  useEffect(() => {
    updateLabel(label)
  }, [label, updateLabel])

  const launchUpdate = async () => {
    await updateFn({
      variables: {
        profile,
        label: updatedLabel,
      },
    })
  }

  return (
    <InputGroup
      rightElement={
        <Button disabled={label === updatedLabel} icon={'edit'} onClick={() => launchUpdate()} />
      }
      value={updatedLabel}
      onChange={(v: any) => updateLabel(v.target.value)}
    />
  )
}

const useInvolvementProfilesConfig: () => InvolvementProfileConfig[] = () => {
  const { loading, data } = useInvolvementProfilesConfigQuery()
  if (loading || !data || !data.involvementCheck) {
    return [
      InvolvementProfile.NoInvolvement,
      InvolvementProfile.Ecolo,
      InvolvementProfile.Employer,
      InvolvementProfile.Ethics,
      InvolvementProfile.Social,
      InvolvementProfile.Territorial,
      InvolvementProfile.MultiCommitted,
    ].map((profile) => ({ profile, label: '' }))
  }
  return data.involvementCheck.profiles
}

const descriptions: Record<InvolvementProfile, string> = {
  NO_INVOLVEMENT: 'Sans engagement particulier',
  ECOLO: 'Profil écolo',
  EMPLOYER: 'Profil employeur',
  ETHICS: 'Profil éthique',
  SOCIAL: 'Profil social',
  TERRITORIAL: 'Profil territorial',
  MULTI_COMMITTED: 'Profil multiengagé (apparait avant la liste des profils concernés)',
  OTHER: '',
}
