import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'
import env from './environment'

import React, { useContext } from 'react'
import { ApiProxy, AuthStore } from '@fa-metier/commons/dist'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { ApolloClient, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

export interface Stores {
  authStore: AuthStore
  routingStore: RouterStore
}

export interface Services {
  apiProxy: ApiProxy
  apolloClient: ApolloClient<NormalizedCacheObject>
}

export type ServiceAndStore = Services & Stores

export const createContext = (): ServiceAndStore => {
  const routingStore = new RouterStore()

  const browserHistory = createBrowserHistory()
  syncHistoryWithStore(browserHistory, routingStore)

  const authStore = new AuthStore(env.AUTH_URL, 'admin-yoda', ['app_metier_admin'])

  const httpLink = createHttpLink({
    uri: `${env.API_URL}/graphql`,
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = authStore.authHeader
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    }
  })

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    headers: {
      authorization: authStore.authHeader,
    },
  })

  const apiProxy = new ApiProxy(authStore, env.API_URL, !env.production)

  const stores: Stores = {
    authStore,
    routingStore,
  }

  const services: Services = {
    apiProxy,
    apolloClient,
  }

  return { ...stores, ...services }
}

const AppContext: React.Context<ServiceAndStore | null> = React.createContext<ServiceAndStore | null>(
  null
)

export default AppContext

export const useAppContext: () => Services & Stores = () => {
  const context = useContext(AppContext)
  if (context == null) {
    throw new Error('No provider used')
  }
  return context
}
