import React, { useContext } from 'react'
import { IToasterProps, Position, Toaster } from '@blueprintjs/core'

export const defaultToasterProps: IToasterProps = {
  autoFocus: false,
  canEscapeKeyClear: true,
  position: Position.TOP,
}

export const ToasterContext: React.Context<Toaster> = React.createContext<Toaster>(null!)

export const useToaster: () => Toaster = () => {
  const toaster = useContext(ToasterContext)
  if (!toaster) {
    throw new Error('No toaster reference was provided !')
  }
  return toaster
}
