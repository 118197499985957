import React, { FC, useState } from 'react'
import { Breadcrumbs, Button } from '@blueprintjs/core'
import { BreadcrumbRenderer, HelpBlock } from '@fa-metier/components'
import { useImpotSocieteParamsQuery } from '../generated/graphql'
import styled from 'styled-components'

// @ts-ignore
import { ImpotSocieteParam } from '@fa-metier/types'
import { useCreateImpotSocieteParam, useUpdateImpotSocieteParam } from './ImpotSocieteParamQueries'
import { useDebounce } from 'react-use'
import NumberFormat from 'react-number-format'

export const Table = styled.table`
  background-color: white;
  padding: 5px;
  border-collapse: collapse;

  * th {
    width: 500px;
    min-width: 500px;
    text-align: left;
    padding-left: 20px;

    :before {
      border-bottom: 2px dotted black;
    }

    position: relative;
    color: var(--main-black-2);
  }

  th span {
    font-weight: normal;

    span {
      font-weight: normal;
    }
  }

  tr.section-title {
    background-color: var(--main-grey-3);
  }

  * td {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    height: 41px;
    padding: 5px 20px;
    border-right: 1px solid var(--main-grey-2);
  }

  * tr {
    vertical-align: baseline;
  }

  * tr th {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: baseline;
    padding: 5px 10px 5px 20px;
  }

  * .bp3-input:disabled {
    background-color: var(--main-grey-3);
    box-shadow: 0 0 0 0 rgba(52, 63, 75, 0), 0 0 0 0 rgba(52, 63, 75, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    color: var(--main-black-1);
  }
`

const Text = styled.div`
  white-space: pre-wrap;
  margin-left: 15px;
  margin-right: auto;
  font-size: 13px;
  color: var(--main-black-2);
  line-height: 26px;
`

export const ImpotSocieteAdmin: FC = () => {
  const params = useImpotSocieteParams()

  const createImpotSocieteParam = useCreateImpotSocieteParam()
  const updateImpotSocieteParam = useUpdateImpotSocieteParam()
  const [updatedParams, setUpdatedParams] = useState<ImpotSocieteParam[]>([])

  const ajouterLigne = () => {
    createImpotSocieteParam({ year: params[params.length - 1].year + 1 })
  }

  useDebounce(
    () => {
      const copyUpdatedParams = [...updatedParams]
      if (copyUpdatedParams.length !== 0) {
        copyUpdatedParams.forEach((param) => {
          updateImpotSocieteParam(param.year, {
            taux1: param.taux1,
            taux2: param.taux2,
            seuil: param.seuil,
          })
        })
        setUpdatedParams([])
      }
    },
    500,
    [updatedParams]
  )

  const updateParam = (year: number, nameField: string, value: string | number | undefined) => {
    if (value || value === 0) {
      const copyParam: ImpotSocieteParam | undefined = params.find((f) => f.year === year)
      if (copyParam !== undefined) {
        if (nameField !== 'seuil') {
          // @ts-ignore
          copyParam[nameField] = value / 100
        } else {
          // @ts-ignore
          copyParam[nameField] = Number(value)
        }

        setUpdatedParams((updatedParams) =>
          [...updatedParams].filter((param) => param.year !== copyParam.year).concat([copyParam])
        )
      }
    }
  }

  return (
    <div>
      <Breadcrumbs
        items={[
          { href: '/home', text: 'Administration' },
          { text: 'Impôt sur les sociétés', current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <h1>Impôt sur les sociétés</h1>

      <HelpBlock>
        <div>
          Paramétrage des différents taux et seuils pour calculer l'impôt sur les sociétés dans le
          volet financier de YODA
        </div>
      </HelpBlock>

      <Table>
        <thead>
          <tr className={'section-title'}>
            <td>Année</td>
            <td>Taux 1</td>
            <td>Taux 2</td>
            <td>Seuil</td>
          </tr>
        </thead>
        <tbody>
          {params.map((it, index) =>
            index === 0 ? (
              <tr key={'ligne_0'}>
                <td key={'ligne_0_year'}>{it.year}</td>
                <td key={'ligne_0_taux1'} style={{ textAlign: 'right', paddingRight: '65px' }}>
                  <NumberFormat
                    value={it.taux1 === undefined || it.taux1 === null ? 0.0 : it.taux1 * 100}
                    decimalScale={2}
                    decimalSeparator={','}
                    displayType={'text'}
                  />{' '}
                  %
                </td>
                <td key={'ligne_0_taux2'} style={{ textAlign: 'right', paddingRight: '65px' }}>
                  <NumberFormat
                    value={it.taux2 === undefined || it.taux2 === null ? 0.0 : it.taux2 * 100}
                    decimalScale={2}
                    decimalSeparator={','}
                    displayType={'text'}
                  />{' '}
                  %
                </td>
                <td key={'ligne_0_seuil'} style={{ textAlign: 'right', paddingRight: '65px' }}>
                  <NumberFormat
                    value={it.seuil === undefined || it.seuil === null ? 0.0 : it.seuil}
                    thousandSeparator={' '}
                    decimalScale={2}
                    decimalSeparator={','}
                    displayType={'text'}
                  />{' '}
                  &euro;
                </td>
              </tr>
            ) : (
              <tr key={'ligne_'.concat(index.toString())}>
                <td key={'ligne_'.concat(index.toString()).concat('year')}>{it.year}</td>
                <td key={'ligne_'.concat(index.toString()).concat('taux1')}>
                  <NumberFormat
                    value={(it.taux1 === undefined || it.taux1 === null ? 0.0 : it.taux1) * 100}
                    decimalScale={2}
                    decimalSeparator={','}
                    allowedDecimalSeparators={[',']}
                    onValueChange={(value) => {
                      updateParam(it.year, 'taux1', value.floatValue)
                    }}
                    style={{ textAlign: 'right' }}
                  />{' '}
                  %
                </td>
                <td key={'ligne_'.concat(index.toString()).concat('taux2')}>
                  <NumberFormat
                    value={(it.taux2 === undefined || it.taux2 === null ? 0.0 : it.taux2) * 100}
                    decimalScale={2}
                    onValueChange={(value) => {
                      updateParam(it.year, 'taux2', value.floatValue)
                    }}
                    decimalSeparator={','}
                    allowedDecimalSeparators={[',']}
                    style={{ textAlign: 'right' }}
                  />{' '}
                  %
                </td>
                <td key={'ligne_'.concat(index.toString()).concat('seuil')}>
                  <NumberFormat
                    value={it.seuil === undefined || it.seuil === null ? 0.0 : it.seuil}
                    decimalScale={2}
                    thousandSeparator={' '}
                    decimalSeparator={','}
                    allowedDecimalSeparators={[',']}
                    onValueChange={(value) => {
                      updateParam(it.year, 'seuil', value.floatValue)
                    }}
                    style={{ textAlign: 'right' }}
                  />{' '}
                  &euro;
                </td>
              </tr>
            )
          )}
          <tr key={'ajouter-ligne-tr'}>
            <td key={'ajouter-ligne-td'}>
              <Button key={'AjouterLigne'} onClick={() => ajouterLigne()}>
                <Text style={{ margin: '0' }}>+ Ajouter une ligne</Text>
              </Button>
            </td>
            <td colSpan={3} key={'ajouter-ligne-spaces'} />
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export const useImpotSocieteParams = () => {
  const { data, loading } = useImpotSocieteParamsQuery()

  const sortImpotSocieteParamArray = (obj1: ImpotSocieteParam, obj2: ImpotSocieteParam) => {
    return obj1.year - obj2.year
  }

  if (!loading && data) {
    return data.impotSocieteParam.impotSocieteParams.slice().sort(sortImpotSocieteParamArray)
  }
  return []
}
