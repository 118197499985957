export interface Environment {
  production: boolean
  AUTH_URL: string
  API_URL: string
  SESAM_URL: string
}

const getEnv = (): Environment => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return {
      production: false,
      AUTH_URL: `${process.env.REACT_APP_AUTH_URL}`,
      API_URL: `${process.env.REACT_APP_API_URL}`,
      SESAM_URL: `${process.env.REACT_APP_SESAM_URL}`,
    }
  }

  // @ts-ignore
  const env = window.__env || {}
  return {
    production: !!env.dev,
    AUTH_URL: env.AUTH_URL,
    API_URL: env.API_URL,
    SESAM_URL: env.SESAM_URL,
  }
}

export default getEnv()
