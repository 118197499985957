import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Question } from '@fa-metier/types/dist'
import { FormGroup, InputGroup } from '@blueprintjs/core'

interface QuestionAdminProps {
  question: Question
  hasChange: boolean
  updateQuestion: (q: Question) => any
}

export const QuestionAdmin: FC<QuestionAdminProps> = ({ question, hasChange, updateQuestion }) => {
  const [edited, setEdited] = useState<boolean>(false)

  const update = (q: Question) => {
    updateQuestion(q)
    setEdited(true)
  }

  useEffect(() => {
    if (!hasChange) {
      setEdited(false)
    }
  }, [hasChange, setEdited])

  return (
    <div
      style={{
        display: 'flex',
        padding: '1em 1em 0 1em',
        flexDirection: 'column',
      }}
    >
      <FormGroup label={`Question :${edited ? ' *' : ''}`} labelFor={`text-input-${question.id}`}>
        <InputGroup
          id={`text-input-${question.id}`}
          value={question.text || ''}
          onChange={(v: ChangeEvent<HTMLInputElement>) =>
            update({ ...question, text: v.target.value })
          }
        />
      </FormGroup>
      <FormGroup
        label="Description critère de notation :"
        labelFor={`description-input-${question.id}`}
      >
        <InputGroup
          id={`description-input-${question.id}`}
          value={question.description || ''}
          onChange={(v: ChangeEvent<HTMLInputElement>) =>
            update({ ...question, description: v.target.value })
          }
        />
      </FormGroup>
    </div>
  )
}
