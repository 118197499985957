import React, { FC, useState } from 'react'
import { MementoTxtExplicatif } from '@fa-metier/types'
import { Editor, HelpBlock, makeOutputData, TextInput } from '@fa-metier/components'
import { useMementoTxtHelpUpdate } from './MementoTxtHelpQueries'
import { useDebounce } from 'react-use'
import styled from 'styled-components'
import { useMementoTxtHelpQuery } from '../generated/graphql'

const Container = styled.div`
  white-space: pre-wrap;
  margin-left: 15px;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
`
const CategoryContainer = styled(Container)`
  white-space: pre-wrap;
  margin-left: 15px;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 8px 32px 8px 24px;
  border-radius: 4px;
`

const MementoTxtHelpEditor: FC<{ it: MementoTxtExplicatif; categoryHasTxtInput?: Boolean }> = ({
  it,
  categoryHasTxtInput = true,
}) => {
  const [txtHelp, setTxtHelp] = useState(makeOutputData(it.aideComprehension))
  const [titleBlock, setTitleBlock] = useState(it.title)
  const updateTxtHelp = useMementoTxtHelpUpdate(it.champ)
  useDebounce(
    () => {
      if (it.aideComprehension !== txtHelp || it.title !== titleBlock) {
        updateTxtHelp({ aideComprehension: txtHelp, title: titleBlock })
      }
    },
    500,
    [txtHelp, titleBlock]
  )
  return (
    <Container className={'mainContainer'}>
      <div className={'questionText'}>
        <TextInput
          type={'text'}
          field={`${it.champ}-title`}
          onValueChange={(v) => setTitleBlock(v)}
          initialValue={titleBlock}
        />
        {categoryHasTxtInput && (
          <Editor
            id={it.champ}
            placeHolder={it.champ}
            initialValue={txtHelp}
            onChange={(v) => setTxtHelp(v)}
          />
        )}
      </div>
    </Container>
  )
}

export const MementoTxtHelp: FC<{ profils: Boolean }> = ({ profils }) => {
  const mementoHelps = useMementoTxtHelpConfig()
  const categories = useMementoTxtHelpConfig().filter((it) => !it.champ.includes('.'))

  return (
    <>
      <HelpBlock>
        <div>Paramétrage des textes d'aide au mémento qui aparaissent sur YODA</div>
      </HelpBlock>
      {categories
        .filter((item) =>
          profils ? item.champ.includes('profils') : !item.champ.includes('profils')
        )
        .map((it) => {
          const children = mementoHelps.filter((item) => item.champ.startsWith(`${it.champ}.`))
          if (children.length > 0) {
            return (
              <CategoryContainer className={'mainContainer'} key={it.champ}>
                <MementoTxtHelpEditor it={it} categoryHasTxtInput={!it.champ.includes('profils')} />
                <div
                  className={'questionText'}
                  style={{ marginTop: 24, marginBottom: 24, paddingLeft: 32 }}
                >
                  {children.map((child) => (
                    <MementoTxtHelpEditor key={child.champ} it={child} />
                  ))}
                </div>
              </CategoryContainer>
            )
          }
          return <MementoTxtHelpEditor key={it.champ} it={it} />
        })}
    </>
  )
}

const useMementoTxtHelpConfig: () => MementoTxtExplicatif[] = () => {
  const { data } = useMementoTxtHelpQuery()

  return data?.mementoTxtHelp ?? []
}
