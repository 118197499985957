import React, { FC, useState } from 'react'
import { Button, Card, Dialog, InputGroup, Spinner, Switch } from '@blueprintjs/core'
import {
  useAddRubriqueMemento,
  useDeleteRubriqueMemento,
  useGetRubriquesMemento,
  useUpdateRubriqueMemento,
} from './RubriqueMementoQueries'
import { RubriqueMemento } from '@fa-metier/types'
import { Editor, makeOutputData } from '@fa-metier/components'
import { useDebounce } from 'react-use'

const Rubrique: FC<{ rubrique: RubriqueMemento; nbRubriques: number }> = ({
  rubrique,
  nbRubriques,
}) => {
  const [rubriqueForm, setRubriqueForm] = useState<RubriqueMemento>(rubrique)
  const [showPopupRemoveRubrique, setShowPopupRemoveRubrique] = useState<boolean>(false)

  const deleteRubriqueMemento = useDeleteRubriqueMemento(rubrique.id)
  const updateRubriqueMemento = useUpdateRubriqueMemento(rubrique.id, false)
  const updateRubriqueMementoOrdre = useUpdateRubriqueMemento(rubrique.id, true)

  useDebounce(
    () => {
      if (rubrique !== rubriqueForm) {
        updateRubriqueMemento({
          titre: rubriqueForm.titre,
          description: rubriqueForm.description,
          aideComprehensionEntrepreneur: rubriqueForm.aideComprehensionEntrepreneur,
          ordre: rubriqueForm.ordre,
          active: rubriqueForm.active,
        })
      }
    },
    500,
    [rubriqueForm]
  )

  return (
    <Card style={{ margin: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 style={{ marginRight: '5px' }}>Titre</h3>
          <InputGroup
            disabled={!rubriqueForm.active}
            value={rubriqueForm.titre ? rubriqueForm.titre : ''}
            onChange={(value) => {
              setRubriqueForm((rubriqueForm) => ({
                ...rubriqueForm,
                titre: value.target.value,
              }))
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            columnGap: '5px',
          }}
        >
          <Switch
            label={'Activée'}
            checked={rubriqueForm.active ? rubriqueForm.active : false}
            onChange={() => {
              setRubriqueForm((rubriqueForm) => ({
                ...rubriqueForm,
                active: !rubriqueForm.active,
              }))
            }}
          />
          {rubrique.ordre < nbRubriques - 1 && (
            <Button
              onClick={() => {
                updateRubriqueMementoOrdre({
                  titre: rubrique.titre,
                  description: rubrique.description,
                  aideComprehensionEntrepreneur: rubriqueForm.aideComprehensionEntrepreneur,
                  ordre: rubrique.ordre + 1,
                  active: rubrique.active,
                })
              }}
              icon={'arrow-down'}
            />
          )}
          {rubrique.ordre > 0 && (
            <Button
              onClick={() =>
                updateRubriqueMementoOrdre({
                  titre: rubrique.titre,
                  description: rubrique.description,
                  aideComprehensionEntrepreneur: rubriqueForm.aideComprehensionEntrepreneur,
                  ordre: rubrique.ordre - 1,
                  active: rubrique.active,
                })
              }
              icon={'arrow-up'}
            />
          )}
          <Button onClick={() => setShowPopupRemoveRubrique(true)} icon={'trash'} />
        </div>
      </div>
      {rubriqueForm.active && (
        <>
          <h4>Aide à la saisie pour le conseiller</h4>
          <Editor
            id={`conseiller_${rubriqueForm.id}`}
            onChange={(v) => {
              setRubriqueForm((rubriqueForm) => ({
                ...rubriqueForm,
                description: v,
              }))
            }}
            initialValue={makeOutputData(rubriqueForm.description)}
          />
          <>
            <h4>Aide à la compréhension pour l'entrepreneur</h4>
            <Editor
              id={`entrepreneur_${rubriqueForm.id}`}
              onChange={(v) => {
                setRubriqueForm((rubriqueForm) => ({
                  ...rubriqueForm,
                  aideComprehensionEntrepreneur: v,
                }))
              }}
              initialValue={makeOutputData(rubriqueForm.aideComprehensionEntrepreneur)}
            />
          </>
        </>
      )}
      <Dialog isOpen={showPopupRemoveRubrique} title={'Attention'} isCloseButtonShown={false}>
        <div style={{ margin: '10px' }}>
          <div>
            Etes-vous sûr de vouloir supprimer la rubrique <b>{rubriqueForm.titre}</b> ?
          </div>
          <div>Cette action ne peut pas être inversée.</div>
        </div>
        <div style={{ padding: '5px' }}>
          <Button
            style={{ marginRight: '0.5em' }}
            onClick={() => setShowPopupRemoveRubrique(false)}
          >
            Annuler
          </Button>
          <Button
            intent={'primary'}
            onClick={() => {
              deleteRubriqueMemento().then(() => {
                setShowPopupRemoveRubrique(false)
              })
            }}
          >
            Valider
          </Button>
        </div>
      </Dialog>
    </Card>
  )
}

export const RubriqueMementoForm: FC = () => {
  const { loading, data } = useGetRubriquesMemento()
  const addRubriqueMemento = useAddRubriqueMemento()

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button style={{ width: 'fit-content', margin: '10px' }} onClick={() => addRubriqueMemento()}>
        Ajouter une rubrique
      </Button>
      {loading && <Spinner />}
      {!loading && data && (
        <div style={{ margin: '10px' }}>
          {data.map((rubrique) => {
            return <Rubrique key={rubrique.id} rubrique={rubrique} nbRubriques={data?.length} />
          })}
        </div>
      )}
    </div>
  )
}
