import React, { FC } from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router'
import { useAppContext } from './config/context'
import { observer } from 'mobx-react-lite'

export interface RouteDefinition {
  path: string
  Component: React.FunctionComponent<RouteComponentProps & { routes?: RouteDefinition[] }> // si on met le 'C' en minuscule on peut pas utiliser de hooks
  title?: string
  routes?: RouteDefinition[]
  secured?: boolean
  renderCondition?: () => boolean
  waitCondition?: () => boolean
}

export const RouteWithSubRoutes: FC<RouteDefinition> = observer((rtDef: RouteDefinition) => {
  const { authStore } = useAppContext()
  const display = !rtDef.renderCondition || rtDef.renderCondition()
  const wait =
    (rtDef.waitCondition && rtDef.waitCondition()) ||
    (rtDef.secured && authStore.authStatus === 'PENDING')
  const validAuth = !rtDef.secured || authStore.authStatus === 'AUTHENTICATED'
  return (
    <Route
      path={rtDef.path}
      render={props => (
        <>
          {wait ? (
            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>Chargement</div>
          ) : (
            <>
              {!validAuth && <Redirect to={'/unauthorized'} />}
              {!display && <Redirect to={'/'} />}
              <rtDef.Component {...props} routes={rtDef.routes} />
            </>
          )}
        </>
      )}
    />
  )
})
