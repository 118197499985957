import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMementoAnalyticsQuery, useNoteByAtQuery } from '../generated/graphql'
import { Breadcrumbs, Button, HTMLTable } from '@blueprintjs/core'
import { BreadcrumbRenderer, TextInput } from '@fa-metier/components'
import '@blueprintjs/core/lib/css/blueprint.css'
import {
  AtMementoClickAnalytics,
  NoteByAt,
  NoteByAtQuery,
  NoteByAtQueryVariables,
} from '@fa-metier/types'
import { ApolloQueryResult } from '@apollo/client/core/types'
import dayjs from 'dayjs'

const defaultStyle = {
  width: 'fit-content',
  borderRadius: '5px',
  outline: 'none',
  height: '28px',
  margin: '5px',
} as React.CSSProperties

export const Analytics: FC = () => {
  const [at, setAt] = useState('')
  const { refetch: refetchNoteByAt } = useNoteByAt('', '')
  const { refetch: refetchClicksByAt } = useMementoAnalyticsQuery({
    variables: { startDate: '', endDate: '' },
  })
  const startDate = useRef<HTMLInputElement | null>(null)
  const endDate = useRef<HTMLInputElement | null>(null)
  const [noteByAt, setNoteByAt] = useState<NoteByAt[] | [] | null | undefined>()
  const [clicksByAt, setClicksByAt] = useState<AtMementoClickAnalytics[] | [] | null | undefined>()
  const [triggerFilterByDate, setTriggerFilterByDate] = useState(false)

  const initEndDate = useCallback(() => {
    if (startDate.current && startDate.current.value && endDate.current && !endDate.current.value) {
      endDate.current.value = dayjs().format('YYYY-MM-DD')
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (triggerFilterByDate) {
      refetchNoteByAt({
        dateDebut: startDate.current?.value ?? '',
        dateFin: endDate.current?.value ?? '',
      }).then(({ data }) => setNoteByAt(data.noteByAt))
      refetchClicksByAt({
        startDate: startDate.current?.value ?? '',
        endDate: endDate.current?.value ?? '',
      }).then(({ data }) => setClicksByAt(data.mementoAnalytics.associationMementoClickAnalytics))
      setTriggerFilterByDate(false)
    }
  }, [triggerFilterByDate, startDate, endDate, refetchNoteByAt, refetchClicksByAt])

  useEffect(() => {
    refetchNoteByAt().then((response) => {
      setNoteByAt(response.data.noteByAt)
    })
    refetchClicksByAt().then((response) =>
      setClicksByAt(response.data.mementoAnalytics.associationMementoClickAnalytics)
    )
  }, [])

  return (
    <div>
      <Breadcrumbs
        items={[
          { href: '/home', text: 'Administration' },
          { text: 'Analytics', current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          gap: '10px',
        }}
      >
        Filtre par date:
        <input
          style={defaultStyle}
          type="date"
          ref={startDate}
          required={true}
          onChange={initEndDate}
        />
        <input style={defaultStyle} type="date" ref={endDate} />
        <Button
          onClick={() => {
            setTriggerFilterByDate(true)
          }}
        >
          Filtre
        </Button>
        <Button
          onClick={() => {
            startDate.current!.value = ''
            endDate.current!.value = ''
            setTriggerFilterByDate(true)
          }}
        >
          Remise à zéro
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          gap: '10px',
        }}
      >
        Filtre AT:
        <TextInput field="AT" type="text" initialValue={at} onValueChange={(v) => setAt(v)} />
      </div>
      <div style={{ display: 'grid', gridGap: '3%' }}>
        <div style={{ gridColumn: '1' }}>
          <h1>Nombre de note par AT</h1>
          {noteByAt?.length ? (
            <BuildNoteByAtGridComponent ntByAt={noteByAt} atNameFilter={at} />
          ) : (
            <div>Chargement en cours...</div>
          )}
        </div>
        <div style={{ gridColumn: '2' }}>
          <h1>Statistiques de Memento générés</h1>
          {clicksByAt?.length ? (
            <MementoStatsTable atMementoData={clicksByAt} atNameFilter={at} />
          ) : (
            <div>Chargement en cours...</div>
          )}
        </div>
      </div>
    </div>
  )
}

const MementoStatsTable: FC<{
  atMementoData: AtMementoClickAnalytics[]
  atNameFilter?: string | null
}> = ({ atMementoData, atNameFilter }) => {
  return (
    <>
      <HTMLTable bordered={true} condensed={true} striped={true}>
        <thead>
          <tr>
            <th>Nom de l'AT</th>
            <th>Nombre de projets</th>
            <th>Nombre de projet avec au moins 1 Mémento générés</th>
            <th>Nombre de projet avec 1 Mémento publiés appli by FA</th>
            <th>Taux de projet sans Mémento générés</th>
            <th>Taux de projet avec 1 seul Mémento générés</th>
            <th>Taux de projet avec plusieurs Mémento générés</th>
          </tr>
        </thead>
        <tbody>
          {atMementoData
            .filter(
              (e) =>
                atNameFilter == null ||
                atNameFilter.length === 0 ||
                e.associationName.toLowerCase().includes(atNameFilter.toLowerCase())
            )
            .map((e) => (
              <tr key={e.associationName}>
                <td>{e.associationName}</td>
                <td>{e.projectsCount}</td>
                <td>{e.projectsCountWithAtLeastOneDownload}</td>
                <td>{e.projectsCountWithAtLeastOnePublication}</td>
                <td>{e.projectsRateWithoutAnyDownload}</td>
                <td>{e.projectsRateWithExactlyOneDownload}</td>
                <td>{e.projectsRateWithMultipleDownloads}</td>
              </tr>
            ))}
        </tbody>
      </HTMLTable>
    </>
  )
}
const BuildNoteByAtGridComponent: FC<{
  ntByAt: null | NoteByAt[] | undefined
  atNameFilter?: string | null
}> = ({ ntByAt, atNameFilter }) => {
  return (
    <>
      {ntByAt?.length && (
        <HTMLTable bordered={true} condensed={true} striped={true}>
          <thead>
            <tr>
              <th>AT</th>
              <th>Nombre de note</th>
            </tr>
          </thead>
          <NoteByAtGridComponent ntByAt={ntByAt} atNameFilter={atNameFilter} />
        </HTMLTable>
      )}
    </>
  )
}

const NoteByAtGridComponent: FC<{
  ntByAt: null | NoteByAt[] | undefined
  atNameFilter?: string | null
}> = ({ ntByAt, atNameFilter }) => {
  const sumAccumulator = (prev: any, curr: any) => prev + curr.noteCount
  const notesTotal = useMemo(() => {
    return () =>
      (ntByAt || [])
        .filter((assoT) =>
          atNameFilter ? assoT.at.toLowerCase().includes(atNameFilter.toLowerCase()) : true
        )
        .reduce(sumAccumulator, 0)
  }, [atNameFilter, ntByAt])

  return (
    <>
      <tbody>
        {ntByAt
          ?.filter(
            (assoT) =>
              atNameFilter == null ||
              atNameFilter.length === 0 ||
              assoT.at.toLowerCase().includes(atNameFilter.toLowerCase())
          )
          .map((el) => (
            <tr key={el.at}>
              <td>{el.at}</td>
              <td>{el.noteCount}</td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td>TOTAL</td>
          <td>{notesTotal()}</td>
        </tr>
      </tfoot>
    </>
  )
}

type useNoteByAtResult = {
  data: NoteByAt[] | [] | null | undefined
  refetch: (
    variables?: Partial<NoteByAtQueryVariables>
  ) => Promise<ApolloQueryResult<NoteByAtQuery>>
}

export const useNoteByAt = (dateDebut?: string, dateFin?: string): useNoteByAtResult => {
  const { data, loading, refetch } = useNoteByAtQuery({ variables: { dateDebut, dateFin } })
  if (!loading && data) {
    return {
      refetch,
      data: data.noteByAt,
    }
  }
  return {
    refetch,
    data: [],
  }
}
