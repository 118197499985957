import { observer } from 'mobx-react-lite'
import { useAppContext } from './config/context'
import { Alignment, Icon, Navbar } from '@blueprintjs/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { FakeLink, UserBlock } from '@fa-metier/components'

const Logo = () => (
  <span style={{ fontSize: '28px', fontWeight: 'bolder', textTransform: 'uppercase' }}>
    <span style={{ color: 'var(--main-green-1)' }}>YODA</span>
  </span>
)

export const AppliNavBar = observer(() => {
  const { authStore } = useAppContext()
  const { user } = authStore
  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <Logo />
          </Link>
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {user && (
          <UserBlock>
            <div>
              <Icon icon={'user'} iconSize={32} />
            </div>
            <div>
              <div>
                {user.firstName} {user.lastName}
              </div>
              <FakeLink onClick={() => authStore.logout()}>Déconnexion</FakeLink>
            </div>
          </UserBlock>
        )}
      </Navbar.Group>
    </Navbar>
  )
})
